<template>
  <div class="signIn">
    <amplify-authenticator username-alias="email">
      <amplify-sign-in
        slot="sign-in"
        :hide-sign-up="true"
        username-alias="email"
      >
      </amplify-sign-in>
    </amplify-authenticator>
  </div>
</template>

<script>
import "@aws-amplify/ui-vue";
import { Auth, Hub } from "aws-amplify";
export default {
  data() {
    return {
      username: "",
      message: {
        showError: false,
        errorMessage: null,
        show: false,
        message: null,
      },
    };
  },
  async created() {
    Hub.listen("auth", async (state) => {
      if (state.payload.event === "signIn") {
        await this.$store.dispatch("authAction");
        this.$store.state.signedIn = true;
        this.$router.push({ path: "/video-manager" }, () => {});
      }
    });

    Auth.currentAuthenticatedUser()
      .then(() => { this.$router.push({ path: "/video-manager" }, () => {}); })
      .catch((err) => { console.log(err) });
  },
  methods: {
  },
  computed: {
    signedIn() {
      return this.$store.state.signedIn;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
